<template>
  <div>
    <template v-if="!isExpandedView">
      <div class="action-link" v-if="data.actionLinkTxt">
        <a href="javascript:void(0)" class="theme-font-medium" role="button" @click="showExpandedView"
          >{{ data.actionLinkTxt }} <ChevronRightBlue class="chevron-icon ml-1" />
        </a>
      </div>
    </template>
    <template v-else>
      <ExpandedViewContent />
    </template>
  </div>
</template>
<script>
import ChevronRightBlue from '../../../assets/svg/chevron-right-blue.svg'
export default {
  name: 'RecreationWidgetContent',
  components: {
    ChevronRightBlue,
    ExpandedViewContent: () => import('./ExpandedViewContent.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showExpandedView() {
      this.$emit('showExpandedView')
    },
  },
}
</script>
<style lang="scss" scoped></style>
